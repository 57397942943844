@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.background-ProductosDestacados {
  background: #f6f9fc;
  padding: 30px 0;
}

/*HEADER*/
.c_flexHeader {
  display: flex;
  justify-content: space-between;

}

/* HOME*/
.d_flexHome{
  display: flex;
  flex-direction: column; /* Organiza los elementos uno debajo del otro */
  align-items: center;    /* Centra los elementos horizontalmente */
  text-align: center; 
}

.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.RText {
  text-align-last: right;
}
input,
button {
  border: none;
  outline: none;
  background-color: none;
}
.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}
.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}
