.wrapper {
  
  margin-top: 0px;
  text-align: center;
  padding-top: 30px;
  background-color: rgb(255, 255, 255);
}

.wrapper .img {
  margin: auto;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-bottom: 20px;
}
.wrapper .img i {
  font-size: 25px;
}
.wrapper .product {
  padding: 30px;
}
.wrapper .product h3 {
  font-weight: 500;
}
.wrapper p {
  font-size: 15px;
  margin-top: 20px;
  color: grey;
}
h2 {
  font-size: 35px;
}

@media (max-width: 1550px) {

  .imagenClientes2 {
    display: flex; /* Asegúrate de que el contenedor sea flex */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente, si el contenedor tiene altura definida */
}

.imagenClientes2 img {
    width: 50%; /* Ajusta el ancho de la imagen */
    margin: auto; /* Centra la imagen dentro del contenedor */
    display: block; /* Asegúrate de que la imagen sea un bloque para que margin auto funcione */
}


}
