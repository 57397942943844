
/*OJO QUE ESTE MISMO GRID LO COMPARTEN LOS PRODUCTOS TAMBIEN UNA GARCHA*/
.grid1 {
    max-width: 1183px;
    margin: 0 auto;
    place-items: center; 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }




.heading-middle{
    margin: auto;
    text-align: center;
    padding-block: 15px;
    font-size: 20px;
  }
  

.productoIndividual {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.productoIndividual h3 {
  font-weight: 500;
  font-size: 20px;

}

.productoIndividual .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}


.productoIndividual .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}

.productoIndividual img {
  width: 80%;
  height: 80%;
}



button.colorLetra{
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    color:#fff;
    margin-top: 10px;
    background: #8b0024; 
    font-weight: bold; 
    color: #fff; 
    border-radius: 5px;
    border: none;
    padding-block: 15px;
    padding-inline: 46px;
    text-align: center;
    width: 180px;
  }

button.colorLetra:hover{
    background: rgb(93, 60, 60);
    box-shadow: rgb(3 0 71 / 35%) 0px 1px 4px;
    transition: 0.3s;
  }

  /*------ Media Queries (max-width: 1130px) ------*/
@media (max-width: 870px) {


.grid1 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}


}


@media (max-width: 490px) {
  .grid1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
}