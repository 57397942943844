.products-container {
  background: #f6f9fc;
  display: flex;
  align-items: flex-start; /* Alinea las categorías y los productos en la parte superior */
  width: 100%;
  padding-bottom: 35px;
}

/*---------category----------*/
.category {
  width: 20%;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
  background-color: #fff;
  padding: 10px 15px 20px 20px;
  border-radius: 5px;
  margin-right: 30px; /* Espacio entre categorías y productos */
  margin-left: 30px;
  position: sticky; /* Hace que las categorías sean fijas cuando se desplaza */
  top: 150px; /* Ajusta la posición desde la parte superior */
}

.category img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.category a {
  margin-left: 12px;
}

.category .box {
  display: flex;
  transition: 0.5s;
  align-items: center; /* Alinea verticalmente en el centro */
}

.category ul li a.active {
  font-size: 17px;
  font-weight: 900;
}

/*---------NuestrosProductos-----------*/
.contentWidth {
  width: 80%; /* Deja espacio para las categorías */
}
  /*------ Media Queries (max-width: 1130px) ------*/
  @media (max-width: 1080px) {
    .category {
      width: auto;
      box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
      background-color: #fff;
      padding: 10px 15px 20px 20px;
      border-radius: 5px;
      margin-right: 20px; /* Espacio entre categorías y productos */
      margin-left: 20px;
      position: sticky; /* Hace que las categorías sean fijas cuando se desplaza */
      top: 150px; /* Ajusta la posición desde la parte superior */
    }

}


@media (max-width: 490px) {
  .category img{
    display: none;
  }
  .category {
    padding: 0px 10px 20px 1px;
    border-radius: 5px;
    margin-right: 10px; /* Espacio entre categorías y productos */
    margin-left: 10px;
    position: sticky; /* Hace que las categorías sean fijas cuando se desplaza */
    top: 150px; /* Ajusta la posición desde la parte superior */
  }
}