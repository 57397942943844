

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container img {
  width: 300px;
}


.product-content2 img {
    width: 50%;
  }

  .product-content2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .img-container2 {
    margin: 1rem 0;
  }
  
  
.background {
  background: #f6f9fc;
  padding: 30px 0;
}

.container {
    max-width: 1200px;
    padding: 0 1rem;
    margin: 0 auto;
}
  
  .product-div {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #fff;
    border-radius: 3px;
  }

  .product-div2 .discount{
    display: none;
  }

  .product-div2 img{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-div2 h3{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-div-right {
    padding: 20px;
  }
  
  .hover-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  
  .hover-container div {
    border: 2px solid rgba(174, 174, 174, 0.7);
    padding: 1rem;
    border-radius: 3px;
    margin: 0 4px 8px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .active {
    border-color: rgb(67, 9, 19)!important;
  }

  
  .hover-container div img {
    width: 80px;
    cursor: pointer;
  }
  
  .product-div-right span {
    display: block;
  }
  
  .product-name {
    font-size: 26px;
    margin-bottom: 22px;
    font-weight: 700;
    letter-spacing: 1px;
    opacity: 0.9;
  }
  
  .product-price {
    font-weight: 700;
    font-size: 24px;
    opacity: 0.9;
    font-weight: 500;
  }
  
  .product-rating {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
  
  .product-rating span {
    margin-right: 6px;
  }
  
  .product-description {
    font-weight: 18px;
    line-height: 1.6;
    font-weight: 300;
    opacity: 0.9;
    margin-top: 22px;
  }
  
  .btn-groups {
    margin-top: 22px;
  }

  .volver-container {
    display: flex;
    margin-bottom: 20px; /* Espacio debajo del botón */
  }
  
  .botonVolver {
    left: 10%; /* Ajusta la distancia desde la derecha */
    position: absolute;
    background-color: #771313;
    font-size: 16px;
    font-family: inherit;
    text-transform: uppercase;
    padding: 15px 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .btn-groups button {
    display: inline-block;
    font-size: 16px;
    font-family: inherit;
    text-transform: uppercase;
    padding: 15px 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 10px;
}

.btn-groups button .fas{
    margin-right: 8px;
}

.buy-now-btn{
    background-color: #000;
    border: 2px solid #000;
}
.buy-now-btn:hover{
    background-color: #fff;
    color: #000;
}

/*-------- Wrraper ----------*/
.centrar {
  flex-direction: column; /* Organiza los elementos uno debajo del otro */
  align-items: center;    /* Centra los elementos horizontalmente */
  text-align: center; 
  padding-bottom: 30px;
}

  /*------ Media Queries (max-width: 1130px) ------*/
  @media (max-width: 1475px) {

    .img-container img {
      width: 300px;
  }
  
      .botonVolver{
        left: auto; /* Ajusta la distancia desde la derecha */
        position: absolute;
        background-color: #771313;
        font-size: 16px;
        font-family: inherit;
        text-transform: uppercase;
        padding: 15px 16px;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;

    }
}
  /*------ Media Queries (max-width: 1130px) ------*/
  @media (max-width: 1150px) {

    .img-container img {
      width: 200px;
  }
     
}

  /*------ Media Queries (max-width: 1130px) ------*/
@media (max-width: 800px) {

  .hide-text-prod{
    display:none;
  }
     
}