.heading-middle1 {
  margin: auto;
  text-align: center;
  padding-block: 35px;
  font-size: 20px;
}

.shop .category {
  padding: 30px;
  height: 90vh;
}
.chead h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
.chead h1:nth-child(2) {
  opacity: 0.5;
  border-left: 3px solid rgba(0, 0, 0, 0.5);
  padding-left: 70px;
}
.shop .category .box {
  background-color: #f6f9fc;
  margin: 15px 0;
  padding: 5px 10px;
  transition: 0.5s;
  border-radius: 5px;
}
.shop .category img {
  width: auto;
  height: auto;
}
.shop .category .box:hover {
  background-color: #fff;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}
.shop .box2 {
  margin-top: 100px !important;
  text-align: center;
}
.shop .category button {
  padding: 10px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
}

.gridProduct {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}



/*---------NEW TOP BOTOM----------*/
.btn-primary {
  background: #e94560;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}

.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}

  /*------ Media Queries (max-width: 1130px) ------*/
  @media (max-width: 990px) {

    .gridProduct {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    
    }

      /*------ Media Queries (max-width: 1130px) ------*/
  @media (max-width: 550px) {

    .gridProduct {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }
    

    }