/*------ Fonts and General Styling ------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap');

a.logo-letra {
    color: rgb(0, 0, 0);
    font-weight: bolder;
    font-style: italic;
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    display: block;
    margin-top: -63px;
    margin-left: 100px;
}

a.logo-letra:hover {
    color: #8b0024;
}

i {
    margin-right: 5px;
}



/*------ Header ------*/
.logo img {
    width: 80px;
    height: 80px;
}

.logo {
    margin-left: 30px;
    margin-top: -10px;
}

.contacto {
    margin: -7px 10px 14px 10px;
}

.clickable-link {
    cursor: pointer;
}

/*------ Search Section ------*/
.search {
    padding: 20px;
    height: 95px;
    box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fff;
    transition: 0.7s;
}

.search.active {
    height: 95px;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
}

/*------ Navigation Links ------*/
.navlink ul {
    line-height: 50px;
}

.navlink ul li {
    margin-top: 5px;
    margin-inline: 26px;
    margin-left: 8px;
}

.navlink ul li a {
    letter-spacing: 0.6px;
    font-size: 18px;
    font-weight: 410;
    position: relative;
}

.navlink ul li a::after {
    content: "";
    background-color: #8b0024;
    position: absolute;
    left: 0;
    top: 29px;
    width: 100%;
    height: 5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.navlink ul li a.active::after,
.navlink ul li a:hover::after {
    opacity: 0.9;
}

/*------ Button Toggle ------*/
.open {
    display: none;
}

/*------ Contact Icons ------*/
.hide-text {
    display: inline;
}

.clickable-link i,
.fa-envelope {
    margin-right: 5px;
}





/*------ Media Queries (max-width: 1130px) ------*/
@media (max-width: 1383px) {
    .hide-text {
        display: none;
    }
}

@media (min-width: 1151px) {
    .navlink ul {
        display: flex; /* Para que el menú se alinee horizontalmente */
        position: relative;
        height: auto;
        width: auto;
    }

    .close, .open {
        display: none;
    }

    .nav-links-MobileMenu {
        display: none; /* Ocultar el menú responsivo */
    }
}


/*------ Media Queries (max-width: 1130px) ------*/
@media (max-width: 1150px) {
    /* Header Adjustments */
    .logo-letra {
        margin-left: 30px;
        font-size: 24px;
    }

    .clickable-link .hide-text {
        display: none;
    }

    .navlink ul {
        padding-top: 20px;
    }

/* Navigation Links */
.nav-links-MobileMenu {
    position: absolute;
    right: 0px;
    margin-left: auto;
    padding-top: 40px;
    top:96px;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transition: all 0.5s ease-in-out;
    padding: 15px;
    background-color: white;
}

    .navlink ul li {
        padding-left: 10px;
        width: 100%;
        transition: all 0.5s ease-in-out;
        text-transform: uppercase;
        line-height: 70px;
    }

    .link {
        display: none;
    }

    .mail{
    display:none;
    }

    .whatsapp{
        display:none;
    }

    /* Button Toggle */
    .close,
    .open {
        position: absolute;
        top: 35px;
        right: 35px;
        color: #000000;
        display: block;
        font-size: 27px;
    }

    /* Adjustments to Search Header */
    .search.active {
        height: 10vh;
    }
}

@media (max-width: 490px) {

    a.logo-letra {
        color: rgb(0, 0, 0);
        font-size: 23px;
        display: flex;
        text-align: center;

        
    }
    .logo{
        margin-left:0px;
        margin-top: 0px;
    }

    /*------ Header ------*/
.logo img {
    width: 50px;
    height: 50px;
}
.logo-letra-empresaria{
margin-top: 20px;
margin-left: -35PX;
}

    
}